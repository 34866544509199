<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Editar Contratación</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <div v-if="this.contratacion">
        <ContratacionForm :contratacion="this.contratacion"></ContratacionForm>
      </div>
    </div>
  </v-container>
</template>

<script>
import ContratacionForm from "../components/ContratacionForm.vue";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "SectorEdit",
  components: {
    ContratacionForm,
  },
  data() {
    return {
      contratacion: null,
    };
  },
  props: ["id"],
  created() {
    PresentismoService.getContratacion(this.id)
      .then((response) => {
        this.contratacion = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
